export const environment = {
    production: false,
    api_domain:"https://boapidev.reach.co.nz/boapi/v1",
    api_domain_for_replacement:"reach.co.nz",
    api_domain_alternate_replacement:"ipsos.co.nz",
    BO_api: "/boapi/v1",
    socketio_url:"https://boapidev.reach.co.nz",
    sessionTimeoutInSeconds: 5400

};
